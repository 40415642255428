<template>
	<div class="outer-wrapper">
		<div class="auth login-password">
			
			<div class="inner-wrapper">

				<div class="heading-section">
					<h1 class="heading3">Enter Password</h1>
					<div class="profile-pic-container" ><img src="@/assets/auth/log-in-profile.png" alt="profile pic" class="profile-pic" /></div>
					<div class="caption account-text">Account</div>
					<div class="caption email">{{ loginUser.email }}</div>
				</div>
			
				<form @submit.prevent="handleSubmit">
					<div class="form-group">
						<label htmlFor="password">Password</label>
						<input type="password" v-model="loginUser.password" name="password" class="form-control" :class="{ 'is-invalid': submitted && errors.has('password') }" />
						<div v-if="submitted && errors.has('password')" class="invalid-feedback">{{ errors.get('password')[0] }}</div>
					</div>
					<Loader :loading="loading"/>
					<div class="form-group">
						<div class="checkbox"><input v-model="loginUser.rememberMe" type="checkbox" id="remember_me"/><label for="remember_me" class="footnote checkbox-text">Remember me</label></div>
					</div>
					<div class="form-group btn-container">
						<button class="button btn-primary" >Continue</button>
						<router-link class="forgot-password" :to="{ name: 'Reset Password' }">Forgot your password?</router-link>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import Loader from '@/components/Loader'

export default {
    data () {
        return {
			submitted: true,
			errors: new Map(),
			loading: false,
        }
    },
	components: {
		Loader
	},
    computed: {
        ...mapState(
			'account', ['status', 'loginUser']),
		...mapState({
				alert: state => state.alert,
			}),
	},
    methods: {
        ...mapActions('account', ['login', 'logout']),
		...mapActions({
			alertError: "alert/error",
			alertSuccess: "alert/success",
		}),
		checkForm() {
			this.errors = new Map();
			let passwordErrors = [];
			if(!this.loginUser.password || this.loginUser.password == null) {
				passwordErrors.push('Password is required');
			}
			if(passwordErrors.length > 0) {
				this.errors.set('password', passwordErrors);
			}
			if (!this.errors.size > 0) {
				return true;
			}
			return false;
		},
		async handleSubmit (e) {
            if(this.checkForm()) {
				if (this.loginUser.email && this.loginUser.password) {
					this.loading = true;
					this.login({ email: this.loginUser.email, password: this.loginUser.password, rememberMe: this.loginUser.rememberMe })
						.then((success) => {
							this.loading = false;
							if(success) {
								this.alertSuccess("Logged in successfully");
								router.push(this.$route.query.redirect || '/');
							}
						})
						.catch(error => {
							this.$rollbar.error(error);
							this.alertError(error);
							this.loading = false;
						});
				}
			}
			e.preventDefault();
        }
	},
	created () {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				route: { name: 'Login', query: { redirect: this.$route.query.redirect}},
			},
			right: {
				type: 'none',
			},
		});

        // reset login status
		this.logout();
		if(this.loginUser.email == null || !this.loginUser.email) {
			router.push({ name: 'Login' });
		}
    },
};
</script>

<style scoped>
	.login-password .form-group {
		padding-bottom: 0;
	}
	.login-password .profile-pic-container {
		margin:auto;
		text-align: center;
		margin-top: 25px;
	}
	.login-password .profile-pic {
		width: 71px;
	}
	.login-password .account-text {
		text-align: center;
		padding-top: 5px;
	}
	.login-password .email {
		text-align: center;
		color: #50555C;
	}
	.login-password .checkbox {
		padding-top: 11px;
		padding-left: 15px;
    	display: inline-flex;
		margin-bottom: 48px;
	}
	.login-password .checkbox-text {
		padding-top: 3px;
    	padding-left: 3px;
	}
	.login-password .forgot-password {
		font-size: 14px;
		margin-top: 20px;
		display: inline-block;
	}
</style>